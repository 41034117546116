import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  bg: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundImage: 'url(/animated/sweaty-workout.jpeg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

function Animated() {
  const styles = useStyles();
  return (
    <div className={styles.bg}>
      <picture>
        <source
          srcSet="/animated/sweaty-workout.webp"
          type="image/webp"
          className={styles.img}
        />
        <img
          src="/animated/sweaty-workout.gif"
          alt="svedig workout"
          className={styles.img}
        />
      </picture>
    </div>
  );
}

export default Animated;
