import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import SEO from '../components/seo';
import Animated from '../components/animated';

// eslint-disable-next-line operator-linebreak
const cursor =
  "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='font-size:24px;'><text y='50%'>🏋️</text></svg>\") 16 0, auto;";

const useStyles = makeStyles(() => ({
  box: {
    height: '100vh',
  },
  link: {
    cursor,
  },
}));

function SvedigPage() {
  const styles = useStyles();
  return (
    <>
      <SEO title="🏋️" />
      <Box component="main" className={styles.box}>
        <a
          href="https://twitter.com/webjay/status/1663959633835196448"
          className={styles.link}
        >
          <Animated />
        </a>
      </Box>
    </>
  );
}

export default SvedigPage;
